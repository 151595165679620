@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply font-sans {
        font-family: 'Inter', sans-serif;
        font-weight: 400;
    };

}

svg {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

select {
    width: 100%;
    padding-right: 30px;
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="%236B7280"  class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5"/></svg>') no-repeat;
    background-position: right 15px center;
    background-size: 16px 16px;
}

input,
textarea,
select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
}

input:focus,
textarea:focus,
select:focus {
    border-width: 1px;
    border-color: #22D3EEFF;

}

input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0;
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid #e5e7eb;
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    outline: none;
    background-color: #fff;
}

input[type="radio"]:checked {
    background-color: #22D3EEFF;
    border-color: #22D3EEFF;
}

input[type="radio"]:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: #ffffff;
    border-radius: 50%;
}

input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    padding: 0;
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid #e5e7eb;
    position: relative;
    cursor: pointer;
    outline: none;
}

input[type="checkbox"]:checked {
    background-color: #22D3EEFF;
    border-color: #22D3EEFF;
}

input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 6px;
    height: 10px;
    border-right: 2px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
}



.sidebar-container {
    overflow: visible;
}

.react-select__control {
    @apply border border-gray-200 rounded-lg;
    @apply text-sm font-light;
    @apply shadow-sm;
    @apply focus:outline-none focus:border-cyan-400;
}

.react-select__option {
    @apply px-4 py-2;
    @apply hover:bg-gray-200;
}

.react-select__multi-value {
    @apply bg-cyan-100;
}

.react-select__multi-value__label {
    @apply text-gray-800;
}

.react-select__multi-value__remove {
    @apply hover:bg-cyan-200;
}

#pdf-controls {
    @apply hidden;
}

.doc-viewer-pagination {
    @apply flex justify-end items-center gap-x-1;
}


.doc-viewer-pagination-prev {
    @apply min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-500 hover:bg-gray-100 focus:outline-none;
}


.doc-viewer-pagination-page {
    @apply flex items-center gap-x-1 text-gray-500;
}


.doc-viewer-pagination-next {
    @apply min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-500 hover:bg-gray-100 focus:outline-none;
}

.ProseMirror:focus {
    @apply outline-0
}

.ProseMirror {
    @apply m-4;
}

/* styles.css */

.tiptap ul p,
.tiptap ol p {
    @apply inline;
}

.tiptap p.is-editor-empty:first-child::before {
    @apply text-[14px] float-left h-0 pointer-events-none;
    content: attr(data-placeholder);
}
.tag-node {
    @apply bg-gray-500 text-white rounded-full px-2 py-1 text-xs;
}
